.black {
  color: #000;
}

*::selection {
  color: #fff;
  background-color: #000;
}

.black, .blue, .orange, .red, .green, .purple {
  font-weight: 600;
}

.red, .red::selection {
  color: #ff1122;
}

.red::selection {
  background-color: #ffe2e2;
}

hr {
  background: linear-gradient(to right, #cceeff, #e4ffe4);
  border: none;
  height: 1px;
}

body {
  font-family: serif;
  padding: 0;
  margin: 0;
  line-height: 1.5;
}

.panel {
  padding: 1em;
  margin: 2em auto;
  width: 61.8vw;
  height: fit-content;
  box-shadow: .1em .1em 2em #DDD;
  border-radius: .4em;

}

.panel > .body {

}

